import { Routes, Route } from 'react-router-dom';
import './App.css';
import Entry from './components/Entry';
import Contact from './components/Contact';
import About from './components/About';
import Services from './components/Services';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Entry />}/>
        <Route path='/contact-us' element={<Contact />}/>
        <Route path='/about-us' element={<About />}/>
        <Route path='/services' element={<Services />}/>
      </Routes>
    </div>
  );
}

export default App;
