import React, { useState } from 'react'
import "../css/Header.css"
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleNav = () => setIsOpen(!isOpen);
    return (
        <nav className="navbar container bg-dark">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item text-white">
                    E & K
                </Link>
            </div>
            <div className={`navbar-menu ${isOpen ? "is-active" : ""}`}>
                <Link to="/" className="navbar-item">
                    Home
                </Link>
                <Link to="/about-us" className="navbar-item">
                    About
                </Link>
                <Link to="/services" className="navbar-item">
                    Services
                </Link>
                <Link to="/contact-us" className="navbar-item">
                    Contact Us
                </Link>
            </div>
        </nav>
    );
};

const Top = () => {
    return (
        <div className="container-fluid bg-light pt-3 d-none d-lg-block">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="mb-2 mb-lg-0">
                        <div className="d-inline-flex">
                            <p><i className="fa fa-envelope me-2"></i>info@e&k.com</p>
                            <p className="text-body px-3">|</p>
                            <p><i className="fa fa-phone-alt me-2"></i>+012 345 6789</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-inline-flex">
                            <a className="text-primary px-2" href="">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a className="text-primary px-2" href="">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a className="text-primary px-2" href="">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a className="text-primary px-2" href="">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a className="text-primary pl-2" href="">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Header = ({others, info}) => {
    // Sample data for images, titles, and descriptions
    const slides = [
        {
            image: '/img/carousel-1.jpg',
            title: 'Tours & Travel',
            description: 'Store of Wonders',
            other: "We believe that travel is more than just visiting new destinations; it's about creating lasting memories, discovering diverse cultures, and embracing the wonders of nature. Whether you're seeking a tranquil escape, an adventurous expedition, or professional travel advice, we are here to guide your every step."
        },
        {
            image: '/img/carousel-2.jpg',
            title: 'Consultancy',
            description: 'Expert Business Strategies',
            other: "We are dedicated to providing expert guidance and strategic insights to help businesses thrive in today's dynamic market place. we offere a comprehensive range of conlultancy services designed to address your unique challenges and unlock new opportunities for growth."
        }
    ];

    // State to keep track of the current slide index
    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to handle the next slide
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    // Function to handle the previous slide
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
    };

    // Get the current slide data
    const { image, title, description, other } = slides[currentIndex];
    return (
        <header
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: "100%",
                height: info ? '65vh' : '80vh', // Adjust height as needed
                textAlign: 'center',
                position: 'relative',
            }}>
            <Top />
            <Navbar />

            <div className='header'>
                <div>
                    {info ? <h1>{info.title}</h1> : <h1>{title}</h1>}
                    {info ? <p>{info.description}</p> : <p className='p'>{description}</p>}
                    {!info &&<p>{other}</p>}
                    {!others &&<a href="" class="py-md-3 px-md-5 mt-2">Book Now <i className="fa-solid fa-right-to-bracket"></i></a>}
                </div>
            </div>
            <button
                onClick={handlePrevious}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    display: others ? 'none' : "block"
                }}
            >
                <i className="fa-solid fa-angles-left"></i>
            </button>
            <button
                onClick={handleNext}
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    display: others ? 'none' : "block"
                }}
            >
                <i className="fa-solid fa-angles-right"></i>
            </button>

        </header >
    )
}

export default Header