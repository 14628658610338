import React from 'react'
import { Grid } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", position: "fixed", top: "0", left:"0", bottom: "0", right:"0", background:"rgba(0,0,0,0.7)", zIndex: 10 }}>
            <Grid
                visible={true}
                height="80"
                width="80"
                color="#fff"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
            /><br />
            <p style={{color:"#fff"}}>Loading, Please Wait ...</p>
        </div>
    )
}

export default Loader