import React from 'react'
import Header from "./Header";
import Main from './Main';
import Footer from './Footer';

const Entry = () => {
  return (
    <div>
        <Header others={false} info={null} />
        <Main />
        <Footer />
    </div>
  )
}

export default Entry