import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import "../css/Contact.css"
import Loader from './Loader'

const Contact = () => {
    const info = {
        title: "Get In Touch",
        description: "Want to get in touch? we'd love to hear from you. Here is how you can reach us ..."
    }

    const sendEmail = async (event) => {
        event.preventDefault();
        alert('please try at a later point, thank you or user the contact information on the left')
        const formData = new FormData(event.target)
    };

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 100);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header others={true} info = {info}/>
            <div className='contact-div d-block d-md-flex gap-4'>
                <div className='flex-grow-1 div'>
                    <h3>Contact Us</h3>
                    <address>
                        Physical Address: <br />
                        CHUSS Building, South Wing 1st Floor Rm 20 Makerere University<br />
                        P.O. Box 7062, Kampala (Uganda)<br />
                        Phone: Tel: +256 414 531 261
                    </address>
                    <iframe className="mt-4" width="100%" height="300px" frameborder="0" style={{border:0, background: 'rgba(3, 73, 134, 0.5'}}
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=makerere university, kampala"
                    allowfullscreen=""></iframe>
                </div>
                <div className='flex-grow-1 div'>
                    <h3>Leave a Reply</h3>
                    <p>Your email address will not be published. Required fields are marked *</p>
                    <form onSubmit={sendEmail}>
                        <label>Comment *</label>
                        <textarea name='comment' placeholder='Enter comment here'></textarea>
                        <div className='d-block d-md-flex gap-4'>
                            <div className='flex-grow-1'>
                                <label>Name *</label>
                                <input type='text' placeholder='Your name' name='name' />
                            </div>
                            <div className='flex-grow-1'>
                                <label>Email *</label>
                                <input type='email' placeholder='your email address' name='email' />
                            </div>
                        </div>
                        <label>Website *</label>
                        <input type='text' placeholder='website name if you have any' name='website'/>
                        <input type='checkbox' name='save' /> Save my name, email, and website in this browser for the next time I comment.<br />
                        <button>Post Comment</button>
                    </form>
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}

export default Contact