import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import "../css/Other.css"
import Loader from './Loader'

const Services = () => {
    const info = {
        title: "Our Services",
        description: "At E & K Tours, we specialize in creating extraordinary travel experiences tailored to your desires. From meticulously planned travel packages and immersive guided tours to exclusive bespoke adventures, our services are designed to ensure every journey is seamless and unforgettable. Explore our offerings and let us turn your travel dreams into reality."
    }

    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 100);
    }, [])
    return (
        <div>
            {spinner ? <Loader /> :
                <>
                    <Header others={true} info={info} />
                    <div className='other-div d-block d-md-flex gap-4'>
                        <div className='div'>
                            <div className="container-fluid py-5">
                                <div className="pt-5 pb-3 bg-light">
                                    <div className="text-center mb-3 pb-3 titled">
                                        <h6 className="text-primary text-uppercase" style={{letterSpacing: "5px"}}>Services</h6>
                                        <h1>Tours & Travel Services</h1>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-route mx-auto mb-4"></i>
                                                <h5 className="mb-2">Travel Guide</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-ticket-alt mx-auto mb-4"></i>
                                                <h5 className="mb-2">Ticket Booking</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-hotel mx-auto mb-4"></i>
                                                <h5 className="mb-2">Hotel Booking</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-route mx-auto mb-4"></i>
                                                <h5 className="mb-2">Travel Guide</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-ticket-alt mx-auto mb-4"></i>
                                                <h5 className="mb-2">Ticket Booking</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-4">
                                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                                <i className="fa fa-2x fa-hotel mx-auto mb-4"></i>
                                                <h5 className="mb-2">Hotel Booking</h5>
                                                <p className="m-0">Justo sit justo eos amet tempor amet clita amet ipsum eos elitr. Amet lorem est amet labore</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </div>
    )
}

export default Services