import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import "../css/Other.css"
import Loader from './Loader'

const About = () => {
    const info = {
        title: "About Us",
        description: "At E & K Tours, we turn dreams into unforgettable journeys. With a passion for exploration and a commitment to exceptional experiences, our expert team crafts personalized adventures that showcase the world’s most captivating destinations. Join us and let’s embark on your next great adventure together."
    }

    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 100);
    }, [])
    return (
        <div>
            {spinner ? <Loader /> :
                <>
                    <Header others={true} info={info} />
                    <div className='other-div d-block d-md-flex gap-4'>
                        <div className='div about-us-content'>
                            <h1 className="about-us-title d-none">About Us</h1>
                            <div className="container-fluid py-5">
                                <div className=" pt-5 pb-3">
                                    <div className="text-center mb-3 pb-3 titled">
                                        <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Guides</h6>
                                        <h2>Our Travel Guides</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                                            <div className="team-item bg-white mb-4">
                                                <div className="team-img position-relative overflow-hidden">
                                                    <img className="img-fluid w-100" src="img/team-1.jpg" alt="" />
                                                    <div className="team-social">
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-twitter"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-facebook-f"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-instagram"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                                                    </div>
                                                </div>
                                                <div className="text-center py-4">
                                                    <h5 className="text-truncate">Guide Name</h5>
                                                    <p className="m-0">Designation</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                                            <div className="team-item bg-white mb-4">
                                                <div className="team-img position-relative overflow-hidden">
                                                    <img className="img-fluid w-100" src="img/team-2.jpg" alt="" />
                                                    <div className="team-social">
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-twitter"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-facebook-f"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-instagram"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                                                    </div>
                                                </div>
                                                <div className="text-center py-4">
                                                    <h5 className="text-truncate">Guide Name</h5>
                                                    <p className="m-0">Designation</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                                            <div className="team-item bg-white mb-4">
                                                <div className="team-img position-relative overflow-hidden">
                                                    <img className="img-fluid w-100" src="img/team-3.jpg" alt="" />
                                                    <div className="team-social">
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-twitter"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-facebook-f"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-instagram"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                                                    </div>
                                                </div>
                                                <div className="text-center py-4">
                                                    <h5 className="text-truncate">Guide Name</h5>
                                                    <p className="m-0">Designation</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                                            <div className="team-item bg-white mb-4">
                                                <div className="team-img position-relative overflow-hidden">
                                                    <img className="img-fluid w-100" src="img/team-4.jpg" alt="" />
                                                    <div className="team-social">
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-twitter"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-facebook-f"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-instagram"></i></a>
                                                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                                                    </div>
                                                </div>
                                                <div className="text-center py-4">
                                                    <h5 className="text-truncate">Guide Name</h5>
                                                    <p className="m-0">Designation</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </div>
    )
}

export default About