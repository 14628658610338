import React from 'react'
import "../css/Main.css"

const Main = () => {

    const data = [
        {
            image: '/img/2.jpg',
            title: 'Karamoja Homestays'
        },
        {
            image: '/img/food.jpg',
            title: 'Food Tourism'
        },
        {
            image: '/img/uganda.jpg',
            title: 'Diverse Lanscape'
        },
        {
            image: '/img/culture2.jpg',
            title: 'Cultural ceremonies'
        },
        {
            image: '/img/galll.jpg',
            title: 'Traditions & Norms'
        },
        {
            image: '/img/elephant.jpg',
            title: 'Wildlife'
        }
    ];
    return (
        <div>
            <div className="text-center mb-3 mt-5 pb-3 titled">
                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>E&K</h6>
                <h2><span className='text-warning'>Into</span> The <span className='text-danger'>Heart</span> of <span className='text-success'>Uganda</span></h2>
            </div>
            {/*About Start */}
            <div className="container-fluid py-5">
                <div className="container pt-1">
                    <div className="row">
                        <div className="col-lg-6" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100" src="img/karamoja1.jpg" style={{ objectFit: "cover", height: "100%" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-1 pb-lg-5 z-2" >
                            <div className="about-text bg-white p-4 p-lg-5 my-lg-5">
                                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>About Us</h6>
                                <h1 className="mb-3 fs-2">We Provide Best Tour Packages In Your Budget</h1>
                                <p>We aim to connect people with the wonders of the world. With a love for travel, we bring you carefully crafted experiences designed to immerse you in Uganda's local culture, wildlife, and nature. We provide unforgettable, sustainable tours to the pearl's most beautiful destinations.</p>
                                <div className="row mb-4">
                                    <div className="col-6">
                                        <img className="img-fluid" src="img/dance1.jpg" alt="" />
                                    </div>
                                    <div className="col-6">
                                        <img className="img-fluid" src="img/e1.jpg" alt="" />
                                    </div>
                                </div>
                                <a href="" className="btn btn-success mt-1" style={{borderRadius: 0}}>Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Destination Uganda, lets Explore! */}
            <div className="container-fluid py-5">
                <div className="container pt-1 pb-3">
                    <div className="text-center mb-3 pb-3 titled">
                        <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Destination Uganda</h6>
                        <h1>Let's Explore!</h1>
                    </div>
                    <div className="row mt-5">
                        {data.map(item =>
                            <div key={item.image} className="col-lg-4 col-md-6 mb-4">
                                <div className="destination-item position-relative overflow-hidden mb-2">
                                    <img className="img-fluid" src={item.image} alt="" />
                                    <a className="destination-overlay text-white text-decoration-none" href="">
                                        <h5 className="text-white">{item.title}</h5>
                                        <span>100 Cities</span>
                                    </a>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            {/* services below */}
            <div className="container-fluid py-1">
                <div className="container pt-1 pb-3">
                    <div className="text-center mb-3 pb-3 titled">
                        <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Services</h6>
                        <h2>Tours, Travel & Consultancy Services</h2>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                <i className="fa fa-2x fa-route mx-auto mb-4"></i>
                                <h5 className="mb-2">Travel Guide</h5>
                                <p className="m-0" style={{textAlign:"justify"}}>we believe that the best way to experience a destination is through the eyes of a local. Our professional travel
                                    guide service offers you personalized, in-depth tours that take you beyond the typical tourist spots and into the heart of
                                    the culture, history, and natural beauty of your chosen location.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                <i className="fa fa-2x fa-ticket-alt mx-auto mb-4"></i>
                                <h5 className="mb-2">Consultancy Agent</h5>
                                <p className="m-0" style={{textAlign:"justify"}}>We offer a comprehensive range of consultancy services designed to help businesses thrive in today’s competitive market.
                                    With a focus on innovation, strategy & efficiency, our team of seasoned consultants works
                                    closely with you to identify challenges, unlock opportunities & create tailored solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="service-item bg-white text-center mb-2 py-5 px-4">
                                <i className="fa fa-2x fa-hotel mx-auto mb-4"></i>
                                <h5 className="mb-2">Strategy & Planning</h5>
                                <p className="m-0" style={{textAlign:"justify"}}>Here are are the range of services offered under consultancy; <br />Market Research & Analysis, Business Plan Development, Growth Strategy Consulting, Financial Advisory, Human Resources Consulting, Human Resources Consulting, Operations Management, Risk and Crisis Management</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Did you know */}
            <div className="container-fluid py-5">
                <div className="container pt-1 pb-3">
                    <div className="text-center mb-3 pb-3 titled">
                        <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Did You Know!</h6>
                        <h2>Captivate Your Imagination</h2>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="package-item bg-white mb-2">
                                <img className="img-fluid" src="img/package-1.jpg" alt="" />
                                <div className="p-4">
                                    <a className="h5 text-decoration-none" href="">Sex & Tourism</a>
                                    <div className="border-top mt-4 pt-4">
                                        <div className="d-flex justify-content-between">
                                            <p>This phenomenon raises complex social, ethical and legal issues, as it intersects with topics such as
                                                human trafficking, exploitation, health risks and local economies but often times in order for one to fully understand
                                                a certain kind of people, you must involve yourself with one. <br />Aspects; Ethical & Legal concerns, Health & Safety, Economic Impact</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="package-item bg-white mb-2">
                                <img className="img-fluid" src="img/food.jpg" alt="" />
                                <div className="p-4">
                                    <a className="h5 text-decoration-none" href="">Food & Tourism</a>
                                    <div className="border-top mt-4 pt-4">
                                        <div className="d-flex justify-content-between">
                                            <p>
                                                Food & tourism are deeply intertwined, with culinary experiences becoming one of the main motivations for travelers.
                                                This involves exploring a destination’s culture and heritage through its cuisine. For many travelers,
                                                food is not just sustenance; it’s an essential part of understanding a place's identity, traditions and lifestyle.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="package-item bg-white mb-2">
                                <img className="img-fluid" src="img/fashion.jpeg" alt="" />
                                <div className="p-4">
                                    <a className="h5 text-decoration-none" href="">Fashion & Tourism</a>
                                    <div className="border-top mt-4 pt-4">
                                        <div className="d-flex justify-content-between">
                                            <p>Travelers often seek to explore a destination's unique style, fashion culture & shopping experiences.
                                                <br />This involves traveling to experience fashion-related events, shop for exclusive brands,
                                                or discover local designers & traditional garments. Fashion tourism offers an immersive way to
                                                experience the style and identity of different cultures.

                                                .

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Registration content */}
            <div className="container-fluid bg-registration py-5" style={{ margin: "90px 0" }}>
                <div className="container py-5 reg">
                    <div className="row align-items-center">
                        <div className="col-lg-7 mb-5 mb-lg-0">
                            <div className="mb-4">
                                <h6 className="text-success text-uppercase" style={{ letterSpacing: "5px" }}>Mega Offer</h6>
                                <h1 className="text-white"><span className="text-success">30% OFF</span> On Services</h1>
                            </div>
                            <p className="text-white">Let us take care of the details while you focus on the adventure. Explore with confidence, knowing that our team of seasoned travel professionals is dedicated to providing you with the highest standard of service.</p>
                            <ul className="list-inline text-white m-0">
                                <li className="py-2"><i className="fa fa-check text-success me-3"></i>Competitive Pricing</li>
                                <li className="py-2"><i className="fa fa-check text-success me-3"></i>Best Services</li>
                                <li className="py-2"><i className="fa fa-check text-success me-3"></i>Worldwide Coverage</li>
                            </ul>
                        </div>
                        <div className="col-lg-5">
                            <div className="card border-0">
                                <div className="card-header bg-success text-center p-4">
                                    <h1 className="text-white m-0 fs-4">Sign Up Now</h1>
                                </div>
                                <div className="card-body rounded-bottom bg-white p-5">
                                    <form>
                                        <div className="form-group">
                                            <input type="text" className="form-control p-2 mb-2" placeholder="Your name" required="required" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control p-2 mb-2" placeholder="Your email" required="required" />
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control custom-select px-4 mb-2" style={{ height: "47px" }}>
                                                <option selected>Select a destination</option>
                                                <option value="1">destination 1</option>
                                                <option value="2">destination 1</option>
                                                <option value="3">destination 1</option>
                                            </select>
                                        </div>
                                        <div>
                                            <button className="btn btn-success w-100 py-3 btn-block" type="submit">Sign Up Now <i className="fa-solid fa-right-to-bracket"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Blog Section */}
            <div className="container-fluid py-5">
                <div className="container pt-1 pb-3">
                    <div className="text-center mb-3 pb-3 titled">
                        <h6 className="text-primary text-uppercase" style={{letterSpacing: "5px;"}}>Our Blog</h6>
                        <h1>Latest From Our Blog</h1>
                    </div>
                    <div className="row mt-5 pb-3">
                        <div className="col-lg-4 col-md-6 mb-4 pb-2">
                            <div className="blog-item">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/blog-1.jpg" alt=""/>
                                        <div className="blog-date">
                                            <h6 className="font-weight-bold mb-n1">01</h6>
                                            <small className="text-white text-uppercase">Jan</small>
                                        </div>
                                </div>
                                <div className="bg-white p-4">
                                    <div className="d-flex mb-2">
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Admin</a>
                                        <span className="text-primary px-2">|</span>
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Tours & Travel</a>
                                    </div>
                                    <a className="h5 m-0 text-decoration-none" href="">Dolor justo sea kasd lorem clita justo diam amet</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 pb-2">
                            <div className="blog-item">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/blog-2.jpg" alt=""/>
                                        <div className="blog-date">
                                            <h6 className="font-weight-bold mb-n1">01</h6>
                                            <small className="text-white text-uppercase">Jan</small>
                                        </div>
                                </div>
                                <div className="bg-white p-4">
                                    <div className="d-flex mb-2">
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Admin</a>
                                        <span className="text-primary px-2">|</span>
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Tours & Travel</a>
                                    </div>
                                    <a className="h5 m-0 text-decoration-none" href="">Dolor justo sea kasd lorem clita justo diam amet</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 pb-2">
                            <div className="blog-item">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/blog-3.jpg" alt=""/>
                                        <div className="blog-date">
                                            <h6 className="font-weight-bold mb-n1">01</h6>
                                            <small className="text-white text-uppercase">Jan</small>
                                        </div>
                                </div>
                                <div className="bg-white p-4">
                                    <div className="d-flex mb-2">
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Admin</a>
                                        <span className="text-primary px-2">|</span>
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Tours & Travel</a>
                                    </div>
                                    <a className="h5 m-0 text-decoration-none" href="">Dolor justo sea kasd lorem clita justo diam amet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main