import React from 'react'
import "../css/Footer.css"

const Footer = () => {
    return (
        <footer>
            <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{marginTop: "90px"}}>
                <div className="row pt-5">
                    <div className="col-lg-3 col-md-6 mb-5">
                        <a href="" className="navbar-brand">
                            <h1 className="text-success"><span className="text-white">E&K</span></h1>
                        </a>
                        <p>Whether you are seeking a tranqil escape, an adventurous expedition or proffessional advice, we are here to guide you every step.</p>
                        <h6 className="text-white text-uppercase mt-4 mb-3" style={{letterSpacing: "5px"}}>Follow Us</h6>
                        <div className="d-flex justify-content-start">
                            <a className="btn btn-outline-success btn-square me-2" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-outline-success btn-square me-2" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-success btn-square me-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-outline-success btn-square" href="#"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-4" style={{letterSpacing: "5px"}}>Our Services</h5>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>About</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Destination</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Services</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Packages</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Guides</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Testimonial</a>
                            <a className="text-white-50" href="#"><i className="fa fa-angle-right me-2"></i>Blog</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-4" style={{letterSpacing: "5px"}}>Usefull Links</h5>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>About</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Destination</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Services</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Packages</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Guides</a>
                            <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right me-2"></i>Testimonial</a>
                            <a className="text-white-50" href="#"><i className="fa fa-angle-right me-2"></i>Blog</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-4" style={{letterSpacing: "5px"}}>Contact Us</h5>
                        <p><i className="fa fa-map-marker-alt me-2"></i>123 Street, New York, USA</p>
                        <p><i className="fa fa-phone-alt me-2"></i>+012 345 67890</p>
                        <p><i className="fa fa-envelope me-2"></i>info@example.com</p>
                        <h6 className="text-white text-uppercase mt-4 mb-3" style={{letterSpacing: "5px"}}>Newsletter</h6>
                        <div className="w-100">
                            <div className="input-group">
                                <input type="text"  className="form-control border-light" style={{padding: "15px", borderRadius: 0}} placeholder="Your Email"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-success text-white px-3" style={{borderRadius: 0}}>Sign Up</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{borderColor: "rgba(256, 256, 256, .1) !important"}}>
                <div className="row">
                    <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                        <p className="m-0 text-white-50">Copyright &copy; <a href="#" className='text-success'>E&K</a>. All Rights Reserved
                    </p>
                </div>
                <div className="col-lg-6 text-center text-md-right">
                    <p className="m-0 text-white-50 ">powered by <a href="https://cynaut-tech.com" className='text-success'>Cynaut Technologies</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer